import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Box, Stack} from '@mui/material';

import {exerciseOptions, fetchData, youtubeOptions} from '../Utils/fetchData';
import Detail from '../Components/Details';
import ExerciseVideos from '../Components/ExerciseVideos';
import SimilarExercises from '../Components/SimilarExercise';

const ExerciseDetail = () => {
    const [exerciseDetail, setExerciseDetail] = useState({});
    const [exerciseVideos, setExerciseVideos] = useState([]);
    const [targetMuscleExercises, setTargetMuscleExercises] = useState([]);
    const [equipmentExercises, setEquipmentExercises] = useState([]);
    const {id} = useParams();

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});

        const fetchExercisesData = async () => {
            const exerciseDbUrl = 'https://exercisedb.p.rapidapi.com';
            const youtubeSearchUrl = 'https://youtube-search-and-download.p.rapidapi.com';

            const exerciseDetailData = await fetchData(`${exerciseDbUrl}/exercises/exercise/${id}`, exerciseOptions);
            setExerciseDetail(exerciseDetailData);

            const exerciseVideosData = await fetchData(`${youtubeSearchUrl}/search?query=${exerciseDetailData.name} exercise`, youtubeOptions);
            setExerciseVideos(exerciseVideosData.contents);

            const targetMuscleExercisesData = await fetchData(`${exerciseDbUrl}/exercises/target/${exerciseDetailData.target}`, exerciseOptions);
            setTargetMuscleExercises(targetMuscleExercisesData);

            const equimentExercisesData = await fetchData(`${exerciseDbUrl}/exercises/equipment/${exerciseDetailData.equipment}`, exerciseOptions);
            setEquipmentExercises(equimentExercisesData);
        };

        fetchExercisesData();
    }, [id]);

    if (!exerciseDetail) return <div>No Data</div>;

    return (
        <Box sx={{mt: {lg: '96px', xs: '60px'}}}>
            <Stack direction={"column"}>
                <Detail exerciseDetail={exerciseDetail}/>
                <ExerciseVideos exerciseVideos={exerciseVideos} name={exerciseDetail.name}/>
                <SimilarExercises targetMuscleExercises={targetMuscleExercises}
                                  equipmentExercises={equipmentExercises}/>
            </Stack>
        </Box>
    );
};

export default ExerciseDetail;
